import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Seo from "../components/Seo"
import Galeria from "../components/pompy/Galeria"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "pompy" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(name: { eq: "pompy" }, sourceInstanceName: { eq: "topImages" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const Fotowoltaika = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="pompy_ciepla_galeria" className="page pompyPage">
      <Seo title="Fotowoltaika" />
      <Top image={imgTop} />
      <Galeria />
      <ContactForm backgroundImage={imgForm} nrTel="781 144 999" />
      <Footer nrTel="781 144 999" email="oze@eka.pl" />
    </div>
  )
}

export default Fotowoltaika
